import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'clsx';
import {
  Paper,
  withStyles,
  Collapse,
  ButtonBase,
  SvgIcon,
} from '@material-ui/core';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Text2 } from '../typography';
import SectionSpacer from './SectionSpacer';
import { TrendsLineIcon } from './Icons';
import { GTAG_EVENT } from '../gtag';

const panelStyles = theme => {
  const panelPadding = theme.spacing(1);
  const panelBorderRadius = theme.shape.borderRadius * 2;
  return {
    root: {
      marginTop: theme.spacing(1.5),
      borderRadius: panelBorderRadius,
      backgroundColor: '#F5F5F5',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${theme.spacing(0.75) + 1}px 0`,
      position: 'relative',
      borderRadius: `${panelBorderRadius}px ${panelBorderRadius}px`,
      width: `100%`,
      borderBottom: '1px solid #F0F0F0',
    },
    headerNoBorder: {
      borderBottom: '0 !important',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    topSpacer: {
      height: theme.spacing(0.5),
    },
    bottomSpacer: {
      height: theme.spacing(1),
    },
    bottomSpacerZero: {
      height: '0',
    },
    icon: {
      position: 'absolute',
      float: 'right',
      right: panelPadding,
      fontSize: '1.8rem',
    },
    chartIcon: {
      position: 'absolute',
      float: 'left',
      left: panelPadding,
    },
    panelTitle: {
      textTransform: 'capitalize !important',
    },
    collapse: {
      paddingLeft: panelPadding,
      paddingRight: panelPadding,
    },
    noMarginTop: {
      marginTop: '0',
    },
  };
};

const CustomArrowActions = ({ IconRender, className, fontSize, color }) => (
  <IconRender className={className} fontSize={fontSize} color={color} />
);

const ExpandIcon = ({ disabled, collapsed, className, customExpandIcons }) => {
  if (disabled) {
    return null;
  }
  return collapsed ? (
    <CustomArrowActions
      IconRender={customExpandIcons[0]}
      className={className}
      fontSize="middle"
      color="inherit"
    />
  ) : (
    <CustomArrowActions
      IconRender={customExpandIcons[1]}
      className={className}
      fontSize="middle"
      color="inherit"
    />
  );
};

const PanelBase = ({
  title,
  children,
  classes,
  collapsible = false,
  showTrendsLineIcon = false,
  initialCollapsed = true,
  customExpandIcons = [ArrowDownwardIcon, ArrowUpwardIcon],
  showNoGap = false,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const onClickHandler = (collapsed, title) => {
    setCollapsed(!collapsed);
    if (collapsed) {
      GTAG_EVENT({ view: 'trends_view', category: 'Trendlines', label: title });
    }
  };

  return (
    <Paper
      className={cx(classes.root, showNoGap ? classes.noMarginTop : '')}
      elevation={0}
    >
      <ButtonBase
        className={cx(classes.header, showNoGap ? classes.headerNoBorder : '')}
        onClick={() => onClickHandler(collapsed, title)}
        disabled={!collapsible}
      >
        {showTrendsLineIcon && (
          <TrendsLineIcon className={classes.chartIcon} fontSize="medium" />
        )}
        <Text2 bold className={classes.panelTitle}>
          {title}
        </Text2>
        {collapsible && (
          <ExpandIcon
            collapsed={collapsed}
            disabled={!collapsible}
            className={classes.icon}
            customExpandIcons={customExpandIcons}
          />
        )}
      </ButtonBase>
      {children && (
        <Collapse className={classes.collapse} in={!collapsible || !collapsed}>
          <SectionSpacer classes={{ root: classes.topSpacer }} />
          {children}
          <SectionSpacer
            classes={{
              root: showNoGap ? classes.bottomSpacerZero : classes.bottomSpacer,
            }}
          />
        </Collapse>
      )}
    </Paper>
  );
};

const Panel = withStyles(panelStyles)(PanelBase);

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  initialCollapsed: PropTypes.bool,
  showTrendsLineIcon: PropTypes.bool,
  showNoGap: PropTypes.bool,
  customExpandIcons: PropTypes.arrayOf(SvgIcon),
};

export { Panel };
