import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  CardContent,
  CardActionArea,
  Collapse,
  Popover,
  useMediaQuery,
} from '@material-ui/core';

// import { ArrowDownIcon } from '../Icons';
import Card from './Card';
import { Text2, Heading1, Note } from '../../typography';
import PerformancePill from '../PerformancePill';
import ClickIndicator from './ClickIndicator';
import { isNullOrUndefined } from '../../util/object';
import { injectIntl } from 'react-intl';
import KPiGraphTabs from './KPiGraphTabs';
import { TrendsLineIcon } from '../Icons';
import { GTAG_EVENT } from '../../gtag';
import { withTraslation } from '../../data/LanguageProvider';

const paddingTopBottom = 1.75;
const paddingLeftRight = 1.5; // as theme.spacing units
const useStyles = props => {
  return makeStyles(theme => ({
    cardRoot: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    popoverSize: {
      '& .MuiPopover-paper': {
        width: '110% !important',
        top: '155px !important',
      },
    },
    content: {
      minHeight: 116,
      boxSizing: 'border-box',
      display: 'inline-flex',
      flexDirection: 'column',
      padding: theme.spacing(paddingTopBottom, paddingLeftRight),
      width: '100%',
    },
    contentKPI: {
      padding: '10px 8px',
      display: 'inline-flex',
      flexDirection: 'column',
      width: '100%',
    },
    customNote: {
      height: '35px',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'center',
    },
    contentNumber: {
      // minHeight: 116,
      height: 84,
      justifyContent: 'space-between',
      // height: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    contentNumberKPI: {
      display: 'inline-flex',
      flexDirection: 'column',
      height: '100% !important',
      alignItems: 'center',
    },
    valueAndPills: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    valueAndPillsKPI: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      justifyContent: 'space-between',
    },
    pills: {
      display: 'flex',
      flexDirection: 'column',
      '& > :last-child': {
        marginTop: 2,
      },
    },
    pillsKPI: {
      display: 'row',
      flexDirection: 'row',
    },
    pill: {
      borderRadius: 12,
      height: 24,
      marginLeft: 'auto',
      minWidth: 'unset',
    },
    pillKPI: {
      height: '18px !important',
      marginLeft: '2px',
      marginTop: '2px',
      minWidth: 'unset',
    },
    pillText: {
      fontSize: '1rem ',
    },
    label: {
      marginBottom: 6,
      display: 'flex',
      flexDirection: 'row-start',
    },
    clickIndicator: {
      float: 'right',
      top: -5,
      position: 'relative',
      marginLeft: 2,
      cursor: 'pointer',
      '@media (max-width: 450px)': {
        fontSize: '15px',
      },
    },
    chartIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.5),
      cursor: 'pointer',
    },
    chartIconKPI: {
      fontSize: '1.3rem !important',
      position: 'absolute',
      left: '6px',
    },
    arrowIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(-0.25),
      position: 'relative',
      cursor: 'pointer',
      float: 'right',
    },
    arrowIconKPI: {
      display: 'none !important',
    },
    chart: {
      height: 320,
      width: '100%',
      paddingBottom: theme.spacing(1.5),
    },
    actionArea: {
      width: '30px',
      borderRadius: '100%',
      '@media (max-width: 450px)': {
        height: '11px !important',
      },
    },
    heading: {
      fontSize: '3rem',
    },
    headingKPI: {
      fontSize: '1.5rem',
    },
    title: {
      fontSize: '1.4rem',
    },
    titleKPI: {
      fontSize: '0.9rem',
    },
    note: {
      display: 'block',
      marginTop: '4px',
    },
    textTitle: {
      width: '100%',
    },
  }));
};

//function to dynamically return the appropriate class names
const getClassName = (
  isLargeScreen,
  calledFromToday,
  classForLargeScreen,
  classForSmallScreen
) => {
  if (isLargeScreen && calledFromToday) {
    return classForLargeScreen;
  } else if (!isLargeScreen && !calledFromToday) {
    return classForSmallScreen;
  } else {
    return classForLargeScreen;
  }
};

const PromotedMetricCard = ({
  onClick,
  metric,
  useLLY,
  style = {},
  color,
  spacing,
  moreDetails = false,
  intl,
  useUsdAmount,
  calledFromToday = false,
}) => {
  const classes = useStyles(style)();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isLargeScreen = useMediaQuery('(min-width:450px)');

  const {
    id,
    label,
    formattedValue,
    formattedLLYValue,
    dpy,
    dpp,
    value,
    value_lly,
    formattedTargetValue,
    formattedTrends,
    format,
  } = metric;

  const pillLabel = useLLY ? 'vsPPY' : 'vsPY';
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (expanded) {
      GTAG_EVENT({ view: 'trends_view', category: 'Trendlines', label: label });
    }
  };

  const handlePopupOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  const trendsLength = formattedTrends
    ? Object.keys(formattedTrends).length
    : 0;
  return (
    <Card color={color} spacing={spacing} className={classes.cardRoot}>
      <CardContent
        className={getClassName(
          isLargeScreen,
          calledFromToday,
          classes.content,
          classes.contentKPI
        )}
      >
        <div
          className={getClassName(
            isLargeScreen,
            calledFromToday,
            classes.contentNumber,
            classes.contentNumberKPI
          )}
        >
          <div className={classes.label}>
            <Text2
              className={`${getClassName(
                isLargeScreen,
                calledFromToday,
                classes.title,
                classes.titleKPI
              )} ${classes.textTitle}`}
            >
              {moreDetails && trendsLength > 0 && (
                <TrendsLineIcon
                  fontSize="large"
                  className={getClassName(
                    isLargeScreen,
                    calledFromToday,
                    classes.chartIcon,
                    classes.chartIconKPI
                  )}
                  onClick={isLargeScreen ? handleExpandClick : handlePopupOpen}
                />
              )}
              {withTraslation(id)}
            </Text2>
            {onClick && (
              <CardActionArea onClick={onClick} className={classes.actionArea}>
                <ClickIndicator
                  className={classes.clickIndicator}
                  onClick={onClick}
                />
              </CardActionArea>
            )}
          </div>
          <div
            className={getClassName(
              isLargeScreen,
              calledFromToday,
              classes.valueAndPills,
              classes.valueAndPillsKPI
            )}
          >
            <div className={calledFromToday ? '' : classes.customNote}>
              {(!isNullOrUndefined(value) || !isNullOrUndefined(value_lly)) && (
                <Heading1
                  caps={false}
                  className={getClassName(
                    isLargeScreen,
                    calledFromToday,
                    classes.heading,
                    classes.headingKPI
                  )}
                >
                  {useLLY && formattedLLYValue
                    ? formattedLLYValue
                    : formattedValue}
                </Heading1>
              )}

              {!isNullOrUndefined(formattedTargetValue) && (
                <Note gray caps={false} className={classes.note}>
                  of {formattedTargetValue} {intl.formatMessage({ id: 'plan' })}
                </Note>
              )}
            </div>
            <div
              className={getClassName(
                isLargeScreen,
                calledFromToday,
                classes.pills,
                classes.pillsKPI
              )}
            >
              {dpp && (
                <PerformancePill
                  classes={{
                    root: getClassName(
                      isLargeScreen,
                      calledFromToday,
                      classes.pill,
                      classes.pillKPI
                    ),
                    pillText: classes.pillText,
                  }}
                  delta={dpp}
                  label={intl.formatMessage({ id: 'vs_plan' })}
                  showArrow={false}
                />
              )}
              {dpy && (
                <PerformancePill
                  classes={{
                    root: getClassName(
                      isLargeScreen,
                      calledFromToday,
                      classes.pill,
                      classes.pillKPI
                    ),
                    pillText: classes.pillText,
                  }}
                  delta={dpy}
                  label={intl.formatMessage({ id: pillLabel })}
                  showArrow={false}
                />
              )}
            </div>
          </div>
        </div>
        {isLargeScreen ? (
          <Collapse
            in={trendsLength > 0 ? expanded : false}
            timeout="auto"
            unmountOnExit
          >
            <KPiGraphTabs
              data={formattedTrends}
              dots={false}
              dataKeyA="value"
              dataKeyB="value_py"
              strokeA="#8884d8"
              strokeB="#D0D0D0"
              useLLY={useLLY}
              format={format}
              useUsdAmount={useUsdAmount}
            />
          </Collapse>
        ) : (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopupClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className={classes.popoverSize}
          >
            <KPiGraphTabs
              data={formattedTrends}
              dots={false}
              dataKeyA="value"
              dataKeyB="value_py"
              strokeA="#8884d8"
              strokeB="#D0D0D0"
              useLLY={useLLY}
              format={format}
              useUsdAmount={useUsdAmount}
            />
          </Popover>
        )}
      </CardContent>
    </Card>
  );
};

PromotedMetricCard.propTypes = {
  onClick: PropTypes.func,
  metric: PropTypes.object.isRequired,
};

export default injectIntl(PromotedMetricCard);
